.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cookie-banner p {
    margin: 0;
    padding: 0 10px;
    flex: 1;
}

.cookie-banner button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;
}

.cookie-banner button:hover {
    background-color: #0056b3;
}

.privacy-link {
    color: #007bff;
    text-decoration: none;
}

.privacy-link:hover {
    text-decoration: underline;
}